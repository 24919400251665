import { getRemoteConfig } from '.';
import { ICompany, IUser } from '@types';
import { IBannerConfig } from '@types';

/**
 * REMOTE CONFIG HELPERS
 *
 */

export const linkPathKeys = {
  support: 'link_support',
  upgrade: 'link_upgrade',
  setupSocial: 'link_setup_social',
  cancellation: 'link_cancellation',
  featureRequest: 'link_feature_request',
  setupWebsite: 'link_setup_website',
  shareToCompany: 'link_shareToCompany_help',
  guidanceExamples: 'link_guidance_examples',
  setupAutoresponder: 'link_setup_autoresponder',
  collectTestimonials: 'link_collect_testimonials',
  onboardingVideoEmbed: 'link_onboarding_video_embed',
  shareToCompanyGoogleHelp: 'link_shareToCompany_google_help',
};

/**
 * get the video object specifying the walkthrough video data for our target viewer
 */
export const getVideoWalkthrough = (targetViewer = 'client') => {
  const walkthroughObjects = JSON.parse(
    getRemoteConfig().getString('video_walkthrough')
  );

  if (walkthroughObjects.hasOwnProperty(targetViewer)) {
    return {
      // add some template data that's the same for all walkthrough videos
      rating: 5,
      type: 'video',
      userInfo: {
        name: 'Software Demo',
      },
      video: {
        streamFormat: 'mp4',
        ...walkthroughObjects[targetViewer],
      },
    };
  }
  return null;
};

export const getBannerConfig = (): IBannerConfig => {
  const bannerConfig = JSON.parse(getRemoteConfig().getString('banner_footer'));
  return bannerConfig;
};

export function getHelpLinkDomain(planId: string): string {
  const helpLinkDomainObject = JSON.parse(
    getRemoteConfig().getString('link_help_domain')
  );

  return helpLinkDomainObject[planId];
}

export function getHelpLinkPath(linkPath: string): string {
  return getRemoteConfig().getString(linkPath);
}

export function getUserPlanId(user: IUser): string {
  /**
   * Some users will have multiple plans. It's fine to just look up the first plan id
   */
  const planId = user?.plans ? Object.keys(user.plans)[0] : 'default';

  return planId;
}

export function generateHelpLinkURL(
  user: IUser,
  company: ICompany,
  linkPath: string
) {
  let planId = '';

  if (user.context.type === 'company' && company && company.type === 'client') {
    planId = 'default';
  } else {
    planId = getUserPlanId(user);
  }

  /**
   * When the planId exists but there no mapping in the remote config,
   * fallback to use the default
   */
  const helpLink = getHelpLinkDomain(planId)
    ? `${getHelpLinkDomain(planId)}/${getHelpLinkPath(linkPath)}`
    : `${getHelpLinkDomain('default')}/${getHelpLinkPath(linkPath)}`;

  return helpLink;
}

export function goToPage(
  user: IUser,
  company: ICompany,
  linkPath: string
): void {
  const url = generateHelpLinkURL(user, company, linkPath);

  window.open(url, '_blank', 'noopener,noreferrer');
}

export const goToClientSupport = () => {
  window.open(
    'https://collect-reviews.helpscoutdocs.com',
    '_blank',
    'noopener,noreferrer'
  );
};

export const goToFeatureRequest = (user: IUser) => {
  const plainId = getUserPlanId(user);

  const featureRequestURL = JSON.parse(
    getRemoteConfig().getString('link_feature_request')
  );

  window.open(featureRequestURL[plainId], '_blank', 'noopener,noreferrer');
};

/**
 * featureLinks is the remote config feature request links
 * @param user
 * @param featureLinks The remote config feature request links
 * @returns
 */
export const getFeatureRequestURL = (
  user: IUser,
  remoteConfigValue: string
): string => {
  const plainId = getUserPlanId(user);

  const featureRequestURL = JSON.parse(remoteConfigValue);

  return featureRequestURL[plainId];
};
