import 'styles/globals.css';
import { Toaster } from 'react-hot-toast';
import AppContextWrapper from 'lib/context/app';
import Head from 'next/head';

import "react-datepicker/dist/react-datepicker.css";

import * as GOOGLE from 'constants/google';

const disableReactDevTools = (): void => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'development') {
    const noop = (): void => undefined;

    const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

    if (typeof DEV_TOOLS === 'object') {
      for (const [key, value] of Object.entries(DEV_TOOLS)) {
        DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
      }
    }
  }
};

disableReactDevTools();

/**
 * Manage required context at the top level to minimize data fetches
 * Only provide company context the dashboard routes
 *
 * @param param0
 * @returns
 */
function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <script src={GOOGLE.GOOGLE_API_URL}></script>
      </Head>
      {/* context for user feedback toast messages */}
      <Toaster />
      <AppContextWrapper>
        <Component {...pageProps} />
      </AppContextWrapper>
    </>
  );
}

export default MyApp;
