import { useRouter } from 'next/router';
import Loader from 'components/common/Loader';

/**
 * load context providers & checker components dynamically,
 * since they are not required for all routes
 */
const AuthProvider = dynamic(() => import('lib/context/auth/provider'), {
  loading: () => <Loader />,
  ssr: false,
});
import dynamic from 'next/dynamic';
import Banner from 'components/dashboard/common/Banner';
const AuthChecker = dynamic(() => import('lib/context/auth/checker'), {
  loading: () => <Loader />,
  ssr: false,
});
const UserChecker = dynamic(() => import('lib/context/user/checker'), {
  loading: () => <Loader />,
  ssr: false,
});

export default function AppContextWrapper({ children }) {
  const router = useRouter();
  const pathname = router.pathname.toLowerCase();

  return (
    <>
      {pathname.includes('dashboard') || pathname.includes('login') ? (
        <AuthProvider>
          {pathname.includes('login') ? (
            children
          ) : (
            <AuthChecker>
              {/*  ensure that the user is authenticated and redirect to login if required
               * when authenticated, wrap with UserProvider for user data
               */}
              <UserChecker>
                {/* ensure that the user data is available
                 * redirect to setup page if required
                 * wrap with CompanyProvider for company data
                 */}
                {children}
                {/* manage banner display at this level so it isn't reloaded when the user changes pages in the dashboard */}
                <Banner />
              </UserChecker>
            </AuthChecker>
          )}
        </AuthProvider>
      ) : (
        <>
          {/* context for auth and company management are not required for public pages */}
          {children}
        </>
      )}
    </>
  );
}
