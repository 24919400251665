import React, { useContext } from 'react';
/** view context */
import UserContext from 'lib/context/user/context';

const ConfiguredComponent = ({ configSettings, Component }) => {
  const { user } = useContext(UserContext);

  // display the banner for new signups who are on trial of the specified plan
  // get the plan from the remoteConfig
  let component = <></>,
    showComponent = false;

  // check the trials first, since those settings take precedence over the settings for plans
  if (Object.prototype.hasOwnProperty.call(configSettings, 'trials')) {
    // there are trial plans with banners configured
    const upgradeTrials = Object.keys(configSettings.trials);
    for (let i = 0; i < upgradeTrials.length; i++) {
      let planId = upgradeTrials[i];
      let planSettings = configSettings.trials[planId];
      // if the setting specifies to show the button when the user is on the trial
      // check to see if the user is on trial for the plan
      if (user.trials && user.trials[planId]) {
        showComponent = true;
        component = <Component config={planSettings} />;
        // break out of the loop once we have the button to display
        break;
      }
    }
  }

  if (
    !showComponent && // there is no banner configured yet
    Object.prototype.hasOwnProperty.call(configSettings, 'plans')
  ) {
    // only check the plans if there isn't already a banner for a trial
    // and there are plans with banners configured for display
    const upgradePlans = Object.keys(configSettings.plans);
    for (let i = 0; i < upgradePlans.length; i++) {
      let planId = upgradePlans[i];
      let planSettings = configSettings.plans[planId];
      // if the setting specifies to show the banner when the user is on the plan
      // check to see if the user is on the plan
      if (user.plans && user.plans[planId]) {
        showComponent = true;
        component = <Component config={planSettings} />;
        // break out of the loop once we have the banner to display
        break;
      }
    }
  }

  return component;
};

export default ConfiguredComponent;
