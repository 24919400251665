import { XIcon } from '@heroicons/react/outline';
import UserContext from 'lib/context/user/context';
import { getBannerConfig } from 'lib/firebase/remoteConfig';

// Banner
/**
 *
 * @packageDocumentation
 */
import React, { useContext, useState } from 'react';
import ConfiguredComponent from '../ConfiguredComponent';

import { BannerProps } from './Banner.types';

const ConfiguredBanner = () => {
  const configSettings = getBannerConfig();

  return (
    <ConfiguredComponent configSettings={configSettings} Component={Banner} />
  );
};

const Banner: React.FC<BannerProps> = ({ config }) => {
  const { user } = useContext(UserContext);

  // get time since account creation in milliseconds
  const accountAgeMillis = Date.now() - user.createdAt;
  let timedOut = false;
  if (config.timeout) {
    // get banner timeout configuration in milliseconds
    // 3600 seconds/hour; 1000 milliseconds / second
    let timeoutMillis = config.timeout * 3600 * 1000;
    timedOut = accountAgeMillis > timeoutMillis;
  }

  const [showBanner, setShowBanner] = useState(!timedOut);
  const openURL = () => {
    const url = config.link;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      {/*
        Make sure you add some bottom padding to pages that include a sticky banner like this to prevent
        your content from being obscured when the user scrolls to the bottom of the page.
      */}
      {showBanner && (
        <div className='fixed inset-x-0 bottom-0 z-200 shadow-md'>
          <div className='relative bg-secondary'>
            <div className='max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8'>
              <div
                className='pr-16 sm:text-center sm:px-16 cursor-pointer'
                onClick={openURL}
              >
                <p className='font-bold text-white uppercase'>
                  <span className='md:hidden'>
                    {config.text} <span aria-hidden='true'>&rarr;</span>
                  </span>
                  <span className='hidden md:inline'>
                    {config.text} <span aria-hidden='true'>&rarr;</span>
                  </span>
                </p>
              </div>
              <div className='absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start'>
                <button
                  type='button'
                  className='flex p-2 rounded-md hover:bg-secondary-light focus:outline-none focus:ring-2 focus:ring-white'
                  onClick={() => setShowBanner(false)}
                >
                  <span className='sr-only'>Dismiss</span>
                  <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfiguredBanner;
