import { createContext } from 'react';
import { IUser } from '@types';

interface IUserContext {
  user: IUser;
}

/**
 * context of logged-in user
 */
const UserContext = createContext<IUserContext>({
  user: null,
});

export default UserContext;
